<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t('customerfield.title') }}</h3>
      </v-col>
    </v-row>
    <br />
    <v-divider></v-divider> <br />
    <v-row>
      <v-col
        ><v-btn @click="$router.push('msetting')" outlined small>
          <v-icon dark>fa-arrow-left</v-icon>{{ $t('all.back') }}
        </v-btn>
        <v-btn color="success" @click="addAdr" small> <v-icon dark>fa-plus</v-icon>{{ $t('all.add') }} </v-btn></v-col
      >
      <v-col valign="top"><v-checkbox v-model="flgSort" label="Включить сортировку"></v-checkbox></v-col>
    </v-row>

    <v-card>
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-left">{{ $t('customerfield.name') }}</th>
            <th class="text-left">{{ $t('customerfield.code') }}</th>
            <th class="text-left">{{ $t('customerfield.tpe') }}</th>
          </tr>
        </thead>

        <draggable
          v-model="tableData"
          :disabled="!flgSort"
          tag="tbody"
          @start="dragging = true"
          @end="dragging = false"
        >
          <tr v-for="item in tableData" :key="item.login" @click="editAdr(item)">
            <td>{{ item.name }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.tpe_name }}</td>
          </tr>
        </draggable>
      </v-simple-table>
    </v-card>

    <modal_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteAdr"
    ></modal_edit>
  </div>
</template>

<script>
import modal_edit from './customerfield_edit.vue'
import draggable from 'vuedraggable'
export default {
  data() {
    return {
      tableData: ['load'],
      modal: '',
      modal_delete: '',
      modalTitle: '',
      modalView: '',
      flgSort: false,
      dragging: false,
    }
  },
  components: {
    modal_edit,
    draggable,
  },
  watch: {
    dragging(val) {
      if (!val) {
        this.saveSort()
      }
    },
  },
  mounted: function() {
    this.dataLoad()
  },
  methods: {
    saveSort: function() {
      var t = this
      var tbl = t.tableData.map(function(name) {
        return name.id
      })
      // var arr = Object.assign({}, this.view)
      this.$http
        .post(this.$store.state.apiUrl + 'customerfield/saveSort/', {
          data: tbl,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
          },
          err => {
            console.log(err)
          },
        )
    },
    modalClose: function(prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    modalDelClose: function(prm) {
      this.modal_delete = ''
      if (prm == 'reload') {
        this.modal = ''
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http.post(this.$store.state.apiUrl + 'customerfield').then(
        response => {
          t.$store.commit('loadingStartShow', false)
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    addAdr: function() {
      this.modal = true
      this.modalView = []
    },
    editAdr: function(view) {
      this.modal = true
      this.modalView = view
    },
    deleteAdr: function(view) {
      this.modal_delete = true
      this.modalView = view
    },
  },
}
</script>

<style></style>
